/**
 * Created by osirvent on 15/05/2017.
 */
angular
    .module('annexaApp')
    .component('annexaOrganDefaultPoints', {
        templateUrl: './components/sec/annexa-organ-default-points/annexa-organ-default-points.html',
        controller: ['$http', 'Language', '$filter', '$scope', '$rootScope', 'RestService', 'CommonAdminModals', 'AnnexaFormlyFactory', 'DialogsFactory', 'CommonService', 'globalModals', '$q', 'DccumentsFactory', 'SecFactory', 'AnnexaModalFactory', 'GlobalDataFactory', 'CacheFactory', 'AdminFactory',
        	function ($http, Language, $filter, $scope, $rootScope, RestService, CommonAdminModals, AnnexaFormlyFactory, DialogsFactory, CommonService, globalModals, $q, DccumentsFactory, SecFactory, AnnexaModalFactory, GlobalDataFactory, CacheFactory, AdminFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            vm.savePoint = function(modal) {
                var self = this;
                var newPoints = [];
                var addToOrder = 0;
                if(vm.defaultPoints && vm.defaultPoints.length > 0){
                	addToOrder = $linq(vm.defaultPoints).select("x => x.orderView").max("x => x");
                }
                
                var title = modal.annexaFormly.fields[1].fieldGroup[0].data.pointTitle;
				var proposalsContainer = modal.annexaFormly.fields[1].fieldGroup[0].data.proposalsContainer;
				var containerOrder = modal.annexaFormly.fields[1].fieldGroup[0].data.containerOrder;
                if(title && (!proposalsContainer || (proposalsContainer && containerOrder))) {
                	var point = {
                            type: { id: modal.annexaFormly.model.row1.type },
                            observations: modal.annexaFormly.fields[1].fieldGroup[0].data.pointObservations,
                            title: modal.annexaFormly.fields[1].fieldGroup[0].data.pointTitle,
                            orderView: 1 + addToOrder,
                            documents: modal.annexaFormly.fields[1].fieldGroup[0].data.documents, 
                            organ: {id:vm.organ},
							proposalsContainer:proposalsContainer,
							containerOrder:containerOrder,
							delegationType: ((modal.annexaFormly.fields[1].fieldGroup[0].data.delegationType)?{id:modal.annexaFormly.fields[1].fieldGroup[0].data.delegationType}:undefined)
                        };
                	var type = $linq(vm.pointTypes).firstOrDefault(undefined,"x => x.id == "+point.type.id);
                	if(_.contains(['VOTE'], ((type)?type.type:''))){
                		point.requiredVoteState = modal.annexaFormly.fields[1].fieldGroup[0].data.requiredVoteState;
                		point.votationType = modal.annexaFormly.fields[1].fieldGroup[0].data.votationType;
                	}
                	if(modal.extra.point){
                		if(modal.extra.sameLevel == true){
                			if(modal.extra.point.parent && modal.extra.point.parent.id){
                				point.parent = {id:modal.extra.point.parent.id};
                			}
                		}else if(modal.extra.sameLevel == false){
                			point.parent = {id:modal.extra.point.id};
                		}
                	}
                	newPoints.push(point);
                }

                if(newPoints && newPoints.length > 0) {
                    $http({
                        url: './api/sec/organ/list',
                        method: 'POST',
                        data: JSOG.encode(newPoints)
                    }).then(function (data) {
                    	vm.defaultPoints = JSOG.decode(data.data).defaultPoints;
                        vm.defaultPointsTree = CommonService.getNestableData(angular.copy(vm.defaultPoints), 'defaultPoint', 'orderView');
                        self.close();
                    }).catch(function (error) {
                        var a = 0;
                    });
                }else{
                	vm.defaultPointsTree = CommonService.getNestableData(angular.copy(vm.defaultPoints), 'defaultPoint', 'orderView');
                }
            }            
            vm.addDefaultPoint = function(event) {
            	vm.addDefaultPointLevel();
            }            
            
            vm.addDefaultPointLevel = function(point, group, sameLevel ){
            	var openModal = false;
            	if(group && vm.pointTypes && vm.pointTypes.length > 0){
            		var groupPoints = $linq(vm.pointTypes).where("x => x.type == 'GROUP'").toArray();
            		if(groupPoints && groupPoints.length > 0){
            			openModal = true;
            		}
            	}else{
            		openModal = true;
            	}
            	if(openModal){
            		var modal = angular.copy(CommonAdminModals.organPointNew);
            		if(group){
	                	modal.title = "global.sec.literals.newGroup";
	                }
	                modal.annexaFormly.model = {};
	                modal.annexaFormly.model.row1 = {};
	                modal.annexaFormly.options = {};
	                modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.isGroup = group;
	                modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.agendaPointTypes = vm.pointTypes;
	                modal.annexaFormly.fields[1].fieldGroup[0].data.weightedVote = ((vm.weightedVote)?vm.weightedVote:false)
	                modal.extra = { 
	                		sessionProperty: 'defaultPoint', 
	                		point: ((point && point.$nodeScope && point.$nodeScope.$modelValue && point.$nodeScope.$modelValue.defaultPoint)?point.$nodeScope.$modelValue.defaultPoint : undefined), 
	                		sameLevel: sameLevel
	                };
	                AnnexaFormlyFactory.showModal('modalNewSessionPoint', modal, vm.savePoint, false);
            	}else{
            		DialogsFactory.error($filter('translate')('global.sec.literals.organHaveNoGroupPoints'));
            	}
            }
            
            var savePointTree = function(point, deferred){
    			RestService.update('./api/sec/organ/move/' + point.id, point).then(function (data) {
    				vm.defaultPoints = data.defaultPoints;	
    				deferred.resolve(true)
                }).catch(function (error) {
                	if(error && error.data && error.data.message == "OrganDefaultPoint parent is proposal container"){
						DialogsFactory.error($filter('translate')('global.sec.literals.errorProposalContainer'));
					}else{
						DialogsFactory.error($filter('translate')('global.sec.literals.errorMovePoint'));
					}
                	deferred.resolve(false)
                });
            }
            
            vm.editAgreementDocument = function(point){
            	if(point && point.$nodeScope && point.$nodeScope.$modelValue && point.$nodeScope.$modelValue.defaultPoint && point.$nodeScope.$modelValue.defaultPoint.agreementDocument){
            		DccumentsFactory.getEditOnlineUrl(point.$nodeScope.$modelValue.defaultPoint.agreementDocument).then(function(data) {
                        window.location.href = data;
                    }).catch(function(error) {
                    	DialogsFactory.error('global.sec.literals.noAgreementDocument', 'Error');
                    });
            	}else{
            		DialogsFactory.error('global.sec.literals.noAgreementDocument', 'Error');
            	}
            }

            vm.seeDefaultPoint = function(point){
            	if(point && point.$nodeScope && point.$nodeScope.$modelValue && point.$nodeScope.$modelValue.defaultPoint && point.$nodeScope.$modelValue.defaultPoint.id){
		        	RestService.findOne('OrganDefaultPoint', point.$nodeScope.$modelValue.defaultPoint.id)
		                .then(function(data) {
		                    var decodedData = JSOG.decode(data.data);
		
		                    var modal = angular.copy(CommonAdminModals.organPointEdit);
		                    if(decodedData.type.type == 'GROUP'){
			                	modal.title = "global.sec.literals.editGroup";
			                }
		                    modal.data = decodedData;
		                    modal.alerts = []; 
		                    modal.languageColumn = vm.languageColumn;
		                    modal.languageColumnEnum = "name";
							modal.languageColumnOrder = "description";
		                    modal.showUrgent = false;
		                    modal.canEdit = true;
		                    modal.requiredVoteStates = SecFactory.requiredVoteStates;
		                    modal.requiredVoteState = $linq(modal.requiredVoteStates).firstOrDefault(undefined,"x => x.id == '"+modal.data.requiredVoteState+"'");
		                    if(vm.weightedVote){
		                    	modal.votationTypes = SecFactory.votationTypesWithWeightedType;
		                    }else{
		                    	modal.votationTypes = SecFactory.votationTypes;
		                    }
		                    modal.votationType = $linq(modal.votationTypes).firstOrDefault(undefined,"x => x.id == '"+modal.data.votationType+"'");
							modal.isActiveCouncillor = (($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.councillor_proposal_type && $rootScope.app.configuration.councillor_proposal_type.active)?true:false);
		                    modal.printEnum = function(value){
		                    	if(value && value.name){
		                    		return $filter('translate')(value.name);
		                    	}else{
		                    		return '';
		                    	}
		                    }
							modal.printOrder = function(value){
		                    	if(value && value.description){
		                    		return $filter('translate')(value.description);
		                    	}else{
									if(value){
										return $filter('translate')('global.sec.literals.containerOrder'+value);
									}else{
			                    		return '';
									}
		                    	}
		                    }
							modal.printProposalsContainer = function(value){
		                    	if(value){
		                    		return $filter('translate')('global.literals.yes');
		                    	}else{
									return $filter('translate')('global.literals.no');
		                    	}
		                    }
							modal.printDelegationTypes = function(value){
		                    	if(value){
		                    		return value[vm.languageColumn];
		                    	}else{
									return "";
		                    	}
		                    }
							modal.containerOrders = [
								{ id: 'T123', description: 'global.sec.literals.containerOrderT123' },
								{ id: 'T12', description: 'global.sec.literals.containerOrderT12' },
								{ id: 'T13', description: 'global.sec.literals.containerOrderT13' },
								{ id: 'T1', description: 'global.sec.literals.containerOrderT1' },
								{ id: 'T23', description: 'global.sec.literals.containerOrderT23' },
								{ id: 'T2', description: 'global.sec.literals.containerOrderT2' },
								{ id: 'T3', description: 'global.sec.literals.containerOrderT3' }
							];
		                    modal.delegationTypes = ((AdminFactory.councillorProposalTypeDelegationType && AdminFactory.councillorProposalTypeDelegationType.length > 0)?angular.copy(AdminFactory.councillorProposalTypeDelegationType):[]);
							modal.delegationTypes.unshift({language1: $filter('translate')('global.commonAdmin.literals.withoutCouncillorDelegationType'),language2: $filter('translate')('global.commonAdmin.literals.withoutCouncillorDelegationType'),language3: $filter('translate')('global.commonAdmin.literals.withoutCouncillorDelegationType'), id: undefined});
							modal.showVote = false;
		                    modal.showVoteType = false;
		                    if(modal.data && modal.data.type && modal.data.type.type && _.contains(['VOTE'], modal.data.type.type)){
		                		modal.showVoteType = true;
		                    }
		                    if(modal.data && modal.data.type && modal.data.type.type && _.contains(['PROPOSAL', 'OPINION'], modal.data.type.type) && modal.data.type.vote){
		                		modal.showVoteType = true;
		                    }
		                	modal.updatePoint = function (val, prop, modelAux) {
		                        var model = ((prop)?this.data:modelAux);
		                        if(prop){
		                        	if(prop == "votationType" || prop == "requiredVoteState" || prop === "containerOrder"){
		                        		model[prop] = val.id;
		                        	}else{
										if(prop === 'proposalsContainer' && !val){
											model.containerOrder = undefined;
											model.delegationType = undefined;
										}
										if(prop === 'delegationType'){
											if(val && val.id){
												model[prop] = val;
											}else{
												model[prop] = undefined;
											}				
										}else{
			                        		model[prop] = val;
										}
		                        	}
									if(model.delegationType && !model.delegationType.id){
										model.delegationType = undefined;
									}
		                        }
		                        RestService.update('./api/sec/organ_default_point/' + model.id, model)
		                            .then(function (data) {
		                                var idDefaultPoint = $linq(vm.defaultPoints).indexOf("x => x.id == " + model.id);
		                                if(idDefaultPoint != -1) {
		                                	if(prop){
		                                		vm.defaultPoints[idDefaultPoint][prop] = val;
		                                	}else{
		                                		if(vm.defaultPoints[idDefaultPoint] && vm.defaultPoints[idDefaultPoint].documents){
		                                			vm.defaultPoints[idDefaultPoint].documents.length = 0;
			                                		if(data.documents){
			                                			_.forEach(data.documents, function(doc){
			                                				vm.defaultPoints[idDefaultPoint].documents.push(doc);
			                                			});
			                                		}
		                                		}else{
		                                			vm.defaultPoints[idDefaultPoint].documents = [];
			                                		if(data.documents){
			                                			_.forEach(data.documents, function(doc){
			                                				vm.defaultPoints[idDefaultPoint].documents.push(doc);
			                                			});
			                                		}
		                                		}
		                                		if(modelAux){
	                                				if(modelAux.documents){
	                                					modelAux.documents.length = 0;
	                                				}else{
	                                					modelAux.documents = [];
	                                				}
	                                				if(data.documents && data.documents.length > 0){
		                                				_.forEach(data.documents, function(doc){
		                                					modelAux.documents.push(doc);
			                                			});
	                                				}
	                                			}
		                                	}
		                                }
		                                vm.defaultPointsTree = CommonService.getNestableData(angular.copy(vm.defaultPoints), 'defaultPoint', 'orderView');
		                        }).catch(function (error) {
		                            //Empty
		                        });
		                    }
		                    AnnexaModalFactory.showModal('modalPointEdit', modal);
		                }).catch(function (error) {
		                    //Empty
		            });
            	}
            }
            
            vm.deleteDefaultPoint = function (point, index) {
               	DialogsFactory.confirm('global.sec.literals.confirmRemoveOrganDefaultPointTitle', 'global.sec.literals.confirmRemoveOrganDefaultPointBody').then(function (dataAux) {
           			if(point){
           				$http({
                            url: './api/sec/organ/defaultpoint/delete/' + point.$nodeScope.$modelValue.defaultPoint.id,
                            method: 'DELETE',
                        }).then(function (data) {
                        	vm.defaultPoints = JSOG.decode(data.data).defaultPoints;		
                        	vm.defaultPointsTree = CommonService.getNestableData(angular.copy(vm.defaultPoints), 'defaultPoint', 'orderView');
                        }).catch(function (error) {
    	                    //Empty
                        });
		        	}
                }).catch(function (data) {
	                    //Empty
	            });
            }
            
            vm.canDeleteThisPoint = function(point){
            	var executedOrderId = undefined;
            	if(point.$nodeScope){
            		executedOrderId = point.$nodeScope.$modelValue.defaultPoint.id;
            	}else{
            		executedOrderId = point.id;
            	}
            	if($linq(vm.defaultPoints).count("x => x.parent && x.parent.id == "+executedOrderId) > 0){
            		return false;
            	}
   				return true;
            }
            
            this.$onInit = function(){
            	if(!vm.defaultPoints){
            		vm.defaultPoints = [];
            	}
        	    
            	vm.defaultPointsTree = CommonService.getNestableData(angular.copy(vm.defaultPoints), 'defaultPoint', 'orderView');
                
            	vm.treeOptions = {
    	            beforeDrop: function(e){
    	            	var deferred = $q.defer();
    	            	var destValueOK = true;
    	            	var destValue = e.dest.nodesScope.node ? e.dest.nodesScope.node.defaultPoint: null;
    	                if(destValue != null && destValue != undefined && destValue.type && destValue.type.type != 'GROUP') {
    	                	destValueOK = false;
    	                }
    	            	if(!destValueOK){
    	            		DialogsFactory.error($filter('translate')('global.sec.literals.nodeParentIsNotAGroup'));
    	                	deferred.reject(false);
    	            	}else{
    	            		if(
    	            				(!destValue && e.source.nodeScope && e.source.nodeScope.$modelValue && e.source.nodeScope.$modelValue.defaultPoint && (!e.source.nodeScope.$modelValue.defaultPoint.parent || !e.source.nodeScope.$modelValue.defaultPoint.parent.id)) 
    	            				|| (destValue && e.source.nodeScope && e.source.nodeScope.$modelValue && e.source.nodeScope.$modelValue.defaultPoint && e.source.nodeScope.$modelValue.defaultPoint.parent && destValue.id == e.source.nodeScope.$modelValue.defaultPoint.parent.id)
    	            		){
    	            			if(e.dest.index == e.source.index){
    	            				destValueOK = false;
    	            			}
    	            		}
    	            		if(!destValueOK){
    	            			deferred.reject(false);
    	            		}else{
    	            			DialogsFactory.confirm('global.sec.literals.movePoint', 'global.sec.literals.confirmmovePoint').then(function (dataAux) {
    	    	            		var sourceValue = e.source.nodeScope.$modelValue.defaultPoint;
    	        	                var sourceParentValue = null;
    	        	                if( e.source.nodeScope.$modelValue.defaultPoint.parent != null &&  e.source.nodeScope.$modelValue.defaultPoint.parent != undefined) {
    	        	                    sourceParentValue = e.source.nodeScope.$modelValue.defaultPoint.parent.id;
    	        	                }
    	        	                var destValue = e.dest.nodesScope.node ? e.dest.nodesScope.node.defaultPoint: null;
    	        	                if(destValue != null && destValue != undefined) {
	        	                        sourceValue.parent = destValue;
    	        	                }else {
    	        	                    sourceValue.parent = null;
    	        	                }
    	        	                sourceValue.orderView = e.dest.index+1;
        	                        savePointTree(sourceValue, deferred);
    	    	                }).catch(function (error) {
									deferred.reject(false);
    	    		            });    	            			
    	            		}
    	            	}
    	            	return deferred.promise;
    	            }
                }
            }
            
        }],
        bindings: {
        	defaultPoints: '=?',
        	pointTypes: '=',
        	organ: '=',
            isNew: '=',
            weightedVote: '=?'
        }
    })
