/**
 * Created by osirvent on 04/05/2017.
 */
angular
    .module('annexaApp')
    .component('annexaMembersOrgan', {
        templateUrl: './components/sec/annexa-members-organ/annexa-members-organ.html',
        controller: ['CommonService', 'Language', 'RestService', '$rootScope', '$filter', 'RestService', 'CommonAdminModals', 'AnnexaFormlyFactory', 'DialogsFactory','$scope', 'GlobalDataFactory', 'CacheFactory', 
        function (CommonService, Language, RestService, $rootScope, $filter, RestService, CommonAdminModals, AnnexaFormlyFactory, DialogsFactory, $scope, GlobalDataFactory, CacheFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            vm.showLongAddressLanguage = $rootScope.app.configuration.show_long_address_language && $rootScope.app.configuration.show_long_address_language.value;
            vm.addOrganMember = function () {
            	var modal = angular.copy(CommonAdminModals.memberOrgnaNew);
                var organField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'organ'");
                if(organField) {
                    organField.templateOptions.labelProp = vm.languageColumn;
                    organField.templateOptions.added = $linq(vm.organs).select("x => x.organ.id").toArray();
                }
                var addressField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'attendeeAddressConvene'");
                if(addressField) {
                	if(vm.organMemberGlobal && vm.organMemberGlobal.attendee && vm.organMemberGlobal.attendee.attendee && vm.organMemberGlobal.attendee.attendee.addresses){
                		_.forEach(vm.organMemberGlobal.attendee.attendee.addresses, function(address){
	                		var thirdAllAddress = new ThirdAddress(undefined, vm.languageColumn, undefined, vm.showLongAddressLanguage);
	                		address.name = thirdAllAddress.getAddressHtml(address, true, true);
                		});
                		addressField.templateOptions.options = $linq(vm.organMemberGlobal.attendee.attendee.addresses).where(function(x){
                			if(x && x.addressType){
                				if($linq(GlobalDataFactory.notificationTypes).firstOrDefault(undefined,"x => x.addressType == '" + x.addressType + "'")){
                					return true;
                				}
                			}
                			return false;
                		}).toArray();
                    }
                }
                modal.annexaFormly.model = {};
                AnnexaFormlyFactory.showModal('modalNewOrganMember', modal, vm.saveOrganMember, false);
            }
            
            vm.saveOrganMember = function(organMember){
            	if(organMember && organMember.annexaFormly && organMember.annexaFormly.model && organMember.annexaFormly.model.row_organ){
            		var org = undefined;
            		if(organMember.annexaFormly.model.row_organ.organ && organMember.annexaFormly.model.row_organ.organ.id){
                		org = $linq(GlobalDataFactory.organs).firstOrDefault(undefined, "x => x.id == "+organMember.annexaFormly.model.row_organ.organ.id);
                	}
		        	var organ = {
		        		organ:((organMember.annexaFormly.model.row_organ.organ)?organMember.annexaFormly.model.row_organ.organ:undefined),
		        		attendeeAddressConvene:((organMember.annexaFormly.model.row_organ.attendeeAddressConvene)?organMember.annexaFormly.model.row_organ.attendeeAddressConvene:undefined),
		        		memberRol:((organMember.annexaFormly.model.row_organ.memberRol)?organMember.annexaFormly.model.row_organ.memberRol:undefined),
		        		vote:((organMember.annexaFormly.model.row_organ.vote)?organMember.annexaFormly.model.row_organ.vote:false),
		        		orderView:((organMember.annexaFormly.model.row_organ.orderView)?organMember.annexaFormly.model.row_organ.orderView:1),
		        		weightedVote:((organMember.annexaFormly.model.row_organ.weightedVote && organMember.annexaFormly.model.row_organ.vote && org && org.weightedVote)?organMember.annexaFormly.model.row_organ.weightedVote:undefined)
		        	};
		        	if(!vm.isNew){
		        		if(organ){
		        			var organAux = angular.copy(organ);
		        			organAux.organMemberGlobal=((vm.organMemberGlobal)?{id:vm.organMemberGlobal.id}:undefined);
		        			organAux.attendeeAddressConvene=((organ.attendeeAddressConvene)?{id:organ.attendeeAddressConvene.id}:undefined);
		        			organAux.memberRol=((organ.memberRol)?{id:organ.memberRol.id}:undefined);
		        			organAux.vote=((organ.vote)?organ.vote:false);
		        			organAux.orderView=((organ.orderView)?organ.orderView:-1);
		        			organAux.organ= {id: organ.organ.id};
		        			organAux.weightedVote= ((organ.weightedVote && organ.vote && org && org.weightedVote)?organ.weightedVote:undefined);
		        			RestService.insert('./api/sec/organ_member', organAux).then(function(data) {
                    			organ.id = data.id;
                    			if(!vm.organs){
                                	vm.organs = [];
                                }
                                vm.organs.push(organ);
                            	organMember.close();
                            }).catch(function() {
                            	organMember.alerts.push("Error");
                            });
                        }
		        	}else{
		        		vm.organs.push(organ);
		        		organMember.close();
		        	}
            	}
            }

            vm.editOrganMember = function (organ, index) {
            	var organToUpdate = angular.copy(organ);
            	var modal = angular.copy(CommonAdminModals.memberOrgnaNew);
                var organField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'organ'");
                if(organField) {
                    organField.templateOptions.labelProp = vm.languageColumn;
                    organField.templateOptions.added = $linq(vm.organs).select("x => x.organ.id").toArray();
                }
                var addressField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'attendeeAddressConvene'");
                if(addressField) {
                	if(vm.organMemberGlobal && vm.organMemberGlobal.attendee && vm.organMemberGlobal.attendee.attendee && vm.organMemberGlobal.attendee.attendee.addresses){
                		_.forEach(vm.organMemberGlobal.attendee.attendee.addresses, function(address){
	                		var thirdAllAddress = new ThirdAddress(undefined, vm.languageColumn, undefined, vm.showLongAddressLanguage);
	                		address.name = thirdAllAddress.getAddressHtml(address, true, true);
                		});
                		addressField.templateOptions.options = $linq(vm.organMemberGlobal.attendee.attendee.addresses).where(function(x){
                			if(x && x.addressType){
                				if($linq(GlobalDataFactory.notificationTypes).firstOrDefault(undefined,"x => x.addressType == '" + x.addressType + "'")){
                					return true;
                				}
                			}
                			return false;
                		}).toArray();
                    }
                }
                modal.annexaFormly.model = {row_organ: organToUpdate, index: index};
            	AnnexaFormlyFactory.showModal('modalNewOrganMember', modal, vm.updateOrganMember, false);
            }
            
            vm.updateOrganMember = function(organMember){
            	if(organMember && organMember.annexaFormly && organMember.annexaFormly.model && organMember.annexaFormly.model.row_organ){
		        	var organ = angular.copy(organMember.annexaFormly.model.row_organ);
		        	if(organ){
		        		var organIndex = -1;
		        		if(organ.id){
				        	organIndex = $linq(vm.organs).indexOf("x => x.id == "+organ.id);
		        		} else if(organMember.annexaFormly.model.index >= 0){
		        			organIndex = organMember.annexaFormly.model.index;
		        		} else {
				        	organIndex = $linq(vm.organs).indexOf("x => x.organMemberGlobal && x.organMemberGlobal.id == "+organ.organMemberGlobal.id);
		        		}
		        		if(organIndex > -1){
				        	if(!vm.isNew){
				        		if(organ){
				        			var organAux = angular.copy(organ);
				        			organAux.organMemberGlobal=((vm.organMemberGlobal)?{id:vm.organMemberGlobal.id}:undefined);
				        			organAux.attendeeAddressConvene=((organ.attendeeAddressConvene)?{id:organ.attendeeAddressConvene.id}:undefined);
				        			organAux.memberRol=((organ.memberRol)?{id:organ.memberRol.id}:undefined);
				        			organAux.vote=((organ.vote)?organ.vote:false);
				        			organAux.orderView=((organ.orderView)?organ.orderView:-1);
				        			organAux.organ= {id: organ.organ.id};
				        			organAux.weightedVote=((organAux.vote && organ.organ && organ.organ.weightedVote)?organAux.weightedVote:undefined);
		                    		RestService.update('./api/sec/organ_member/' + organAux.id, organAux).then(function (data) {
		                    			if(organIndex || organIndex == 0){
			                    			if(vm.organs && vm.organs.length >= organIndex){
			                                	vm.organs[organIndex] = organ;
			                                }
		                    			}
		                    			organMember.close();
		                            }).catch(function () {
		                            	organMember.alerts.push("Error");
		                            });
		                        }
				        	}else{
				        		vm.organs[organIndex] = organ;
				        		organMember.close();
				        	}
			        	}
		        	}
            	}
            }
            
            vm.deleteOrganMember = function (organ, index) {
               	DialogsFactory.confirm('global.sec.literals.confirmRemoveMemberOrganTitle', 'global.sec.literals.confirmRemoveMemberOrganBody').then(function (dataAux) {
           			if(organ){
		        		var organIndex = -1;
		        		if(organ.id){
				        	organIndex = $linq(vm.organs).indexOf("x => x.id == "+organ.id);
		        		} else if(index >= 0){
		        			organIndex = index;
		        		} else{
				        	organIndex = $linq(vm.organs).indexOf("x => x.organMemberGlobal && x.organMemberGlobal.id == "+organ.organMemberGlobal.id);
		        		}
		        		if(organIndex > -1){
				        	if(!vm.isNew){
				        		if(organ && organ.id && ( organIndex || organIndex == 0) && vm.organs && vm.organs.length >= organIndex){
		                    		RestService.delete('./api/sec/organ_member/' + organ.id).then(function (data) {
		                    			vm.organs.splice(organIndex, 1);
	                                }).catch(function (error) {
		                                console.log(error);
		                            });
		                        }
				        	}else{
				        		vm.organs.splice(organIndex, 1);
				        	}
			        	}
		        	}
                }).catch(function (data) {
	                    //Empty
	            });
            }
            
            this.$onInit = function(){
            	if(vm.organs && vm.organs.length > 0){
	                _.forEach(vm.organs, function (value, index) {
	                	if(value.attendeeAddressConvene && value.attendeeAddressConvene.id){
	                		var thirdAllAddress = new ThirdAddress(undefined, vm.languageColumn, undefined, vm.showLongAddressLanguage);
	                		value.attendeeAddressConvene.name = thirdAllAddress.getAddressHtml(value.attendeeAddressConvene, true, true);
	                	}
	                });
            	}
            }
            
        }],
        bindings: {
            organs: '=',
            isNew: '=',
            organId:'=?',
            organMemberGlobal: '='
        }
    })