angular
    .module('annexaApp')
    .component('annexaBoxObjectSessionProposedOrder',{
        templateUrl: './components/sec/annexa-box-object-session-proposed-order/annexa-box-object-session-proposed-order.html',
        require: {
            sessionComponent: '^^annexaBoxObjectSession'
        },
        controller: ['$q', 'SecFactory', '$filter', '$rootScope', 'ErrorFactory', '$state', '$scope', 'RestService', 'HelperService', 'AnnexaPermissionsFactory', 'GlobalDataFactory', 'Language', 'AnnexaFormlyFactory', 'CommonService', 'DialogsFactory', 'HeaderService', '$nestable','SecModals','$http', 'AnnexaModalFactory','NotificationFactory', 
       	function ($q, SecFactory, $filter, $rootScope, ErrorFactory, $state, $scope, RestService, HelperService, AnnexaPermissionsFactory, GlobalDataFactory, Language, AnnexaFormlyFactory, CommonService, DialogsFactory, HeaderService, $nestable, SecModals, $http, AnnexaModalFactory, NotificationFactory) {
            //region General
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            
            if ($rootScope&& $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.sec_convene_button_literal && $rootScope.app.configuration.sec_convene_button_literal.update && 
            		$rootScope.app.configuration.sec_convene_button_literal.update != '') {
            	vm.updateButtonLiteral  = $rootScope.app.configuration.sec_convene_button_literal.update;
            } else {
            	vm.updateButtonLiteral  = $filter('translate')('global.sec.literals.updateConvene')
            }
            
            if ($rootScope&& $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.sec_convene_button_literal && $rootScope.app.configuration.sec_convene_button_literal.send && 
            		$rootScope.app.configuration.sec_convene_button_literal.send != '') {
            	vm.sendButtonLiteral  = $rootScope.app.configuration.sec_convene_button_literal.send;
            } else {
            	vm.sendButtonLiteral  = $filter('translate')('global.sec.literals.sendConvene')
            }
            
            if ($rootScope&& $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.sec_convene_button_literal && $rootScope.app.configuration.sec_convene_button_literal.generate && 
            		$rootScope.app.configuration.sec_convene_button_literal.generate != '') {
            	vm.generateButtonLiteral  = $rootScope.app.configuration.sec_convene_button_literal.generate;
            } else { 
            	vm.generateButtonLiteral  = $filter('translate')('global.sec.literals.generateConvene')
            }
            
            vm.savePoint = function(modal) {
                var self = this;
                var orders = [];
                var addToOrder = (vm.sessionComponent.session[modal.extra.sessionProperty] && vm.sessionComponent.session[modal.extra.sessionProperty].length) ?
                		vm.sessionComponent.session[modal.extra.sessionProperty].length : 0;

                var proposals = modal.annexaFormly.fields[1].fieldGroup[0].data.proposals;
                var title = modal.annexaFormly.fields[1].fieldGroup[0].data.pointTitle;

                if(proposals && proposals.length > 0) {
                    _.forEach(proposals, function (value, key) {
                        if(value.selected) {
                        	var point = {
                                    session: { id: vm.sessionComponent.session.id },
                                    type: { id: modal.annexaFormly.model.row1.type },
                                    observations: '',
                                    proposal: { id: value.id },
                                    orderView: (orders.length + 1) + addToOrder,
                                    documents: value.documents
                                };
                        	var type = $linq(SecFactory.session.organ.agendaPointTypes).firstOrDefault(undefined,"x => x.id == "+point.type.id);
                        	if(_.contains(['VOTE'], ((type)?type.type:''))){
                        		point.requiredVoteState = value.requiredVoteState;
                        		point.votationType = value.votationType;
                        	}else if(_.contains(['PROPOSAL', 'OPINION'], ((type)?type.type:'')) && type && type.vote){
                        		point.requiredVoteState = value.requiredVoteState;
                        		point.votationType = value.votationType;
                        	}
                        	if(modal.extra.point){
                        		if(modal.extra.sameLevel == true){
                        			if(modal.extra.point.parent && modal.extra.point.parent.id){
                        				point.parent = {id:modal.extra.point.parent.id};
                        			}
                        		}else if(modal.extra.sameLevel == false){
                        			point.parent = {id:modal.extra.point.id};
                        		}
                        	}
                        	orders.push(point);
                        }
                    });
                } else if(title) {
                	var point = {
                            session: { id: vm.sessionComponent.session.id },
                            type: { id: modal.annexaFormly.model.row1.type },
                            observations: modal.annexaFormly.fields[1].fieldGroup[0].data.pointObservations,
                            title: modal.annexaFormly.fields[1].fieldGroup[0].data.pointTitle,
                            orderView: 1 + addToOrder,
                            documents: modal.annexaFormly.fields[1].fieldGroup[0].data.documents
                        };
                	var type = $linq(SecFactory.session.organ.agendaPointTypes).firstOrDefault(undefined,"x => x.id == "+point.type.id);
                	if(_.contains(['VOTE'], ((type)?type.type:''))){
                		point.requiredVoteState = modal.annexaFormly.fields[1].fieldGroup[0].data.requiredVoteState;
                		point.votationType = modal.annexaFormly.fields[1].fieldGroup[0].data.votationType;
                	}else if(_.contains(['PROPOSAL', 'OPINION'], ((type)?type.type:'')) && type && type.vote){
                		point.requiredVoteState = modal.annexaFormly.fields[1].fieldGroup[0].data.requiredVoteState;
                		point.votationType = modal.annexaFormly.fields[1].fieldGroup[0].data.votationType;
                	}
                	if(modal.extra.point){
                		if(modal.extra.sameLevel == true){
                			if(modal.extra.point.parent && modal.extra.point.parent.id){
                				point.parent = {id:modal.extra.point.parent.id};
                			}
                		}else if(modal.extra.sameLevel == false){
                			point.parent = {id:modal.extra.point.id};
                		}
                	}
                	orders.push(point);
                }

                if(orders && orders.length > 0) {
                    $http({
                        url: modal.extra.apiBaseUrl + '/list',
                        method: 'POST',
                        data: JSOG.encode(orders)
                    }).then(function (data) {
                    	var acAux = vm.sessionComponent.session.archiveClassificationAux;
                        vm.sessionComponent.session = JSOG.decode(data.data);
                        vm.sessionComponent.session.archiveClassificationAux = acAux;
                    	$rootScope.$broadcast('sessionPointOrderAdded', { origin: modal.extra.sessionProperty })
                        self.close();
                    }).catch(function (error) {
                        var a = 0;
                    });
                }
            }
            
            vm.seePoint = function(point){
            	if(point && point.$nodeScope && point.$nodeScope.$modelValue && point.$nodeScope.$modelValue.proposedOrder && point.$nodeScope.$modelValue.proposedOrder.id){
		        	RestService.findOne('SessionProposedOrder', point.$nodeScope.$modelValue.proposedOrder.id)
		                .then(function(data) {
		                    var decodedData = JSOG.decode(data.data);
		
		                    var modal = angular.copy(SecModals.sessionPointEdit);
		                    if(decodedData.type.type == 'GROUP'){
			                	modal.title = "global.sec.literals.editGroup";
			                }
		                    modal.data = decodedData;
		                    modal.extra = {activeTab: 0};
		                    modal.alerts = [];
		                    modal.languageColumn = vm.languageColumn;
		                    modal.languageColumnEnum = "name";
		                    modal.showUrgent = false;
		                    modal.canEdit = vm.sessionComponent.canEdit;
		                    modal.requiredVoteStates = SecFactory.requiredVoteStates;
		                    modal.requiredVoteState = $linq(modal.requiredVoteStates).firstOrDefault(undefined,"x => x.id == '"+modal.data.requiredVoteState+"'");
		                    if(vm.sessionComponent.session && vm.sessionComponent.session.organ && vm.sessionComponent.session.organ.weightedVote){
		                    	modal.votationTypes = SecFactory.votationTypesWithWeightedType;
		                    }else if(modal.data && modal.data.votationType && modal.data.votationType === 'WEIGHTED'){
		                    	modal.votationTypes = SecFactory.votationTypesWithWeightedType;
		                    }else{
		                    	modal.votationTypes = SecFactory.votationTypes;
		                    }
		                    modal.votationType = $linq(modal.votationTypes).firstOrDefault(undefined,"x => x.id == '"+modal.data.votationType+"'");
		                    modal.printEnum = function(value){
		                    	if(value && value.name){
		                    		return $filter('translate')(value.name);
		                    	}else{
		                    		return '';
		                    	}
		                    }
		                    modal.showVote = false;
		                    modal.showVoteType = false;
		                    if(modal.data && modal.data.type && modal.data.type.type && _.contains(['VOTE'], modal.data.type.type)){
		                		modal.showVoteType = true;
		                    }else if(modal.data && modal.data.type && modal.data.type.type && _.contains(['PROPOSAL', 'OPINION'], modal.data.type.type) && modal.data.type.vote){
		                    	modal.showVoteType = true;
                        	}
		                	modal.updatePoint = function (val, prop, modelAux) {
		                        var model = ((prop)?this.data:modelAux);
		                        if(prop){
		                        	if(prop == "votationType" || prop == "requiredVoteState"){
		                        		model[prop] = val.id;
		                        	}else{
		                        		model[prop] = val;
		                        	}
		                        }
		                        RestService.update('./api/sec/session_proposed_order/' + model.id, model)
		                            .then(function (data) {
		                                var idProposedOrder = $linq(vm.sessionComponent.session.proposedOrder).indexOf("x => x.id == " + model.id);
		                                if(idProposedOrder != -1) {
		                                	if(prop){
		                                		vm.sessionComponent.session.proposedOrder[idProposedOrder][prop] = val;
		                                	}else{
		                                		if(vm.sessionComponent.session.proposedOrder[idProposedOrder] && vm.sessionComponent.session.proposedOrder[idProposedOrder].documents){
			                                		vm.sessionComponent.session.proposedOrder[idProposedOrder].documents.length = 0;
			                                		if(data.documents){
			                                			_.forEach(data.documents, function(doc){
			                                				vm.sessionComponent.session.proposedOrder[idProposedOrder].documents.push(doc);
			                                			});
			                                		}
		                                		}else{
		                                			vm.sessionComponent.session.proposedOrder[idProposedOrder].documents = [];
			                                		if(data.documents){
			                                			_.forEach(data.documents, function(doc){
			                                				vm.sessionComponent.session.proposedOrder[idProposedOrder].documents.push(doc);
			                                			});
			                                		}
		                                		}
		                                		if(modelAux){
	                                				if(modelAux.documents){
	                                					modelAux.documents.length = 0;
	                                				}else{
	                                					modelAux.documents = [];
	                                				}
	                                				if(data.documents && data.documents.length > 0){
		                                				_.forEach(data.documents, function(doc){
		                                					modelAux.documents.push(doc);
			                                			});
	                                				}
	                                			}
		                                	}
		                                }
		                                vm.sessionComponent.session.modified = true;
		                                $rootScope.$broadcast('sessionPointOrderUpdated', {origin: 'proposedOrder'})
		                            }).catch(function (error) {
		                            //Empty
		                        });
		                    }
		                	modal.validationCircuitObservations = function(){
		                    	var proposal = this.data.proposal;
		                    	if(proposal) {
		            				var dataModal = {
		        	                    row: true,
		        	                    colClass: 'col-sm-12',
		        	                    labelClass: ''
		        	                };
		        	                var modal = {
		        	                    title: 'global.literals.validationCircuitObservations',
		        	                    size: 'modal-md',
		        	                    icon: '',
		        	                    submitModal: function () {
		        	                    },
		        	                    alerts: []		
		        	                }
		        	                modal.annexaFormly = new AnnexaFormly();
		        	                
		        	                var fields = [];
		        	                if(proposal.reviewObservations) {
		        	                	fields.push(modal.annexaFormly.createField('reviewDisagreements', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.literals.validationCircuitReviewDisagreements',  proposal.reviewDisagreement ? $filter("translate")("global.literals.yes") : $filter("translate")("global.literals.no")),dataModal));
		        	                	fields.push(modal.annexaFormly.createField('reviewObservations', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.literals.validationCircuitReviewObservations',  proposal.reviewObservations),dataModal));
		        	                }
		        	                if(proposal.validateObservations) {
		        	                	fields.push(modal.annexaFormly.createField('reviewDisagreements', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.literals.validationCircuitValidateDisagreements',  proposal.validateDisagreement ? $filter("translate")("global.literals.yes") : $filter("translate")("global.literals.no")),dataModal));
		        	                	fields.push(modal.annexaFormly.createField('reviewObservations', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.literals.validationCircuitValidateObservations',  proposal.validateObservations),dataModal));
		        	                }
		        	                if(proposal.acceptObservations) {
		        	                	fields.push(modal.annexaFormly.createField('reviewDisagreements', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.literals.validationCircuitAcceptDisagreements',  proposal.acceptDisagreement ? $filter("translate")("global.literals.yes") : $filter("translate")("global.literals.no")),dataModal));
		        	                	fields.push(modal.annexaFormly.createField('reviewObservations', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.literals.validationCircuitAcceptObservations',  proposal.acceptObservations),dataModal));
		        	                }
		        	                modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
		        	                modal.annexaFormly.options = {};
		        	                modal.annexaFormly.options.formState = {readOnly: false};
		        	                AnnexaFormlyFactory.showModal("validationCircuitObservationsModal", modal, undefined, false, true); 
		                    	}
		        			}
		                    AnnexaModalFactory.showModal('modalPointEdit', modal);
		                }).catch(function (error) {
		                    //Empty
		            });
            	}
            }
            
            vm.newPoint = function(isGroup) {
            	vm.addPointLevel(undefined, isGroup, undefined);
            }            
            
            vm.addPointLevel = function(point, group, sameLevel ){
            	var openModal = false;
            	var groupPoints = undefined;
            	if(group && SecFactory.session && SecFactory.session.organ && SecFactory.session.organ.agendaPointTypes){
            		groupPoints = $linq(SecFactory.session.organ.agendaPointTypes).where("x => x.type == 'GROUP'").toArray();
            		if(groupPoints && groupPoints.length > 0){
            			openModal = true;
            		}
            	}else{
            		openModal = true;
            	}
            	if(openModal){
            		var modal = angular.copy(SecModals.sessionPointNew);
	                modal.annexaFormly.model = {};
	                modal.annexaFormly.model.row1 = {};
	                modal.annexaFormly.options = {};
	                modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.isGroup = group;
	                modal.annexaFormly.fields[1].fieldGroup[0].data.weightedVote = ((vm.sessionComponent.session && vm.sessionComponent.session.organ && vm.sessionComponent.session.organ.weightedVote)?vm.sessionComponent.session.organ.weightedVote:false)
	                if(group){
	                	modal.title = "global.sec.literals.newGroup";
	                }
	                modal.extra = { 
	                		session: vm.sessionComponent.session, 
	                		apiBaseUrl: './api/sec/session_proposed_order', 
	                		sessionProperty: 'proposedOrder', 
	                		point: ((point && point.$nodeScope && point.$nodeScope.$modelValue && point.$nodeScope.$modelValue.proposedOrder)?point.$nodeScope.$modelValue.proposedOrder : undefined), 
	                		sameLevel: sameLevel
	                };
	                AnnexaFormlyFactory.showModal('modalNewSessionPoint', modal, vm.savePoint, false);
            	}else{
            		DialogsFactory.error($filter('translate')('global.sec.literals.organHaveNoGroupPoints'));
            	}
            }

            vm.deletePoint = function(point){
            	if(point && point.$nodeScope && point.$nodeScope.$modelValue && point.$nodeScope.$modelValue.proposedOrder && point.$nodeScope.$modelValue.proposedOrder.id ){
	            	$http({
	                    url: './api/sec/session_proposed_order/check_delete/' + point.$nodeScope.$modelValue.proposedOrder.id,
	                    method: 'GET'
	                }).then(function(data) {
	                	if(data && data.data){
		                	$http({
		                        url: './api/sec/session_proposed_order/' + point.$nodeScope.$modelValue.proposedOrder.id,
		                        method: 'DELETE'
		                    }).then(function(data) {
		                    	var indexOfPoint = $linq(vm.sessionComponent.session.proposedOrder).indexOf("x => x.id == "+point.$nodeScope.$modelValue.proposedOrder.id);
		                    	if(indexOfPoint != -1) {
		                    		vm.sessionComponent.session.proposedOrder.splice(indexOfPoint,1);
                                }
		                    	
		                    	var indexOfCollapsed = $linq(vm.initProposedOrderCollapsed).indexOf("x => x.id == "+point.$nodeScope.$modelValue.proposedOrder.id);
		                    	if(indexOfCollapsed != -1) {
		                    		vm.initProposedOrderCollapsed.splice(indexOfCollapsed,1);
                                }
		                    	
		                    	vm.sessionComponent.session.modified = true;
		                        $rootScope.$broadcast('sessionPointOrderDeleted', { origin: 'proposedOrder' })
		                    }).catch(function(error) {
		                        //Empty
		                    });
	                	}else{
	                		DialogsFactory.error($filter('translate')('global.sec.literals.organHaveSonsNotDelete'));
	                	}
	                }).catch(function(error) {
	                	 //Empty
	                });
            	}
            }
            
            vm.canDeleteThisPoint = function(point){
            	if($linq(vm.sessionComponent.session.proposedOrder).count("x => x.parent && x.parent.id == "+point.$nodeScope.$modelValue.proposedOrder.id) > 0){
            		return false;
            	}else{
            		return true;            		
            	}
            }
            
            var savePointTree = function(point, deferred){
    			RestService.update('./api/sec/session_proposed_order/move/' + point.id, point).then(function (data) {
    				if(data && data.session && data.session.proposedOrder){
    					vm.sessionComponent.session.proposedOrder = data.session.proposedOrder;    					
    				}
    				vm.sessionComponent.session.modified = true;
                    deferred.resolve(true)
                }).catch(function (error) {
                	deferred.resolve(false)
                });
            }
            
            var canGenerateConvene = function () {
            	if(vm.sessionComponent.session.proposedOrder && vm.sessionComponent.session.proposedOrder.length > 0){
            		return true;
            	}else{
            		return false;
            	}
            }

            vm.generateConvene = function () {
                if(canGenerateConvene()) {
                    DialogsFactory.confirm(vm.generateButtonLiteral, 'global.sec.literals.confirmGenerateConveneBody').then(function (dataAux) {
                    	SecFactory.createDocumentModal('SCO', null, 'session', vm.sessionComponent.session, true)
                        .then(function(data) {
                            var selfData = data;
                            $http({
                                url: './api/sec/session/' + vm.sessionComponent.session.id + '/convene',
                                method: 'POST', 
                                data: JSOG.encode(selfData.request)
                            }).then(function(data) {
                            	var acAux = vm.sessionComponent.session.archiveClassificationAux;
                                vm.sessionComponent.session = JSOG.decode(data.data);
                            	vm.sessionComponent.session.archiveClassificationAux = acAux;
                            	selfData.modal.close();
                                $rootScope.$broadcast('sessionGenerateConvene', {})
                            }).catch(function (error) {
                            	selfData.modal.close();
                            	if(error && error.data && error.data.message == 'Exist document'){
                            		DialogsFactory.confirm('global.sec.literals.confirmGenerateConveneTitle', 'global.sec.literals.confirmGenerateConveneExistErrorBody').then(function (dataAux) {
                            			vm.updateConvene();
                            		}).catch(function(error) {
                                        $state.reload();
                                    });                            		
                            	}else if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
									var msg = $filter('translate')('global.tram.errors.processTemplate')+((error.data.message)?'<br/><br/>'+error.data.message:'');
									DialogsFactory.error(msg, $filter('translate')('DIALOGS_ERROR_MSG'));
                            	}else if(error && error.data && error.data.message && error.data.message.toUpperCase().startsWith("We are working with this session.".toUpperCase())){
									DialogsFactory.error($filter('translate')('global.literals.errorSessionWorking'), $filter('translate')('DIALOGS_ERROR_MSG'));
    							}else{
				                	DialogsFactory.error($filter('translate')('global.sec.errors.cantGenerateConvene'));
                            	}
                            });
                        }).catch(function(error) {
                            //Empty;
                        })
                    }).catch(function (data) {
                    	//Empty;
		            });
                } else {
                	DialogsFactory.error($filter('translate')('global.sec.errors.cantGenerateConvene'));
                }
            }
            
            vm.sendConvene = function () {
            	var haveModifiedAttendees = function(){
            		var modified = false;
            		_.forEach(vm.sessionComponent.session.attendees, function(attendee){
            			if(attendee.member && attendee.member.id){
	            			var member = $linq(vm.sessionComponent.session.organ.members).firstOrDefault(undefined, "x => x.id == "+attendee.member.id);
	            			var a;
	            			if(member){
	            				(
	            					(
	            						(!attendee.memberPosition && member.organMemberGlobal && !member.organMemberGlobal.memberPosition) || 
	            						(attendee.memberPosition && member.organMemberGlobal && member.organMemberGlobal.memberPosition && 
	            							(attendee.memberPosition == member.organMemberGlobal.memberPosition.language1 || 
	            							attendee.memberPosition == member.organMemberGlobal.memberPosition.language2 || 
	            							attendee.memberPosition == member.organMemberGlobal.memberPosition.language3)
            							)
            						)?a=0:modified = true
            					);
	            				((!attendee.politicalParty && member.organMemberGlobal && !member.organMemberGlobal.politicalParty) || (attendee.politicalParty && member.organMemberGlobal && member.organMemberGlobal.politicalParty && attendee.politicalParty.id == member.organMemberGlobal.politicalParty.id )?a=0:modified = true);
           						((member.organMemberGlobal && attendee.govermmentTeam == member.organMemberGlobal.govermmentTeam)?a=0:modified = true);
           						((attendee.vote == member.vote)?a=0:modified = true);
	            				((attendee.attendee && member.organMemberGlobal && member.organMemberGlobal.attendee && attendee.attendee == member.organMemberGlobal.attendee.completeName)?a=0:modified = true);
	            			}else{
	            				modified = true;
	            			}
            			}
            		});
            		return modified;
            	}
            	var correctStatusConvene = false;
            	if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.sec_document_types && $rootScope.app.configuration.sec_document_types["SCO"] && $rootScope.app.configuration.sec_document_types["SCO"].valid_states && $rootScope.app.configuration.sec_document_types["SCO"].valid_states.length > 0){
            		if(_.contains($rootScope.app.configuration.sec_document_types["SCO"].valid_states, vm.sessionComponent.conveneDocument.document.docStatus)){
            			correctStatusConvene = true;
            		}
            	}else if(vm.sessionComponent.conveneDocument && vm.sessionComponent.conveneDocument.document && vm.sessionComponent.conveneDocument.document.docStatus == 'SIGNED'){
            		correctStatusConvene = true;
            	}
            	if(correctStatusConvene){
            		var isMembersCorrect = true;
            		var organMemberNoInSession = $linq(vm.sessionComponent.session.organ.members).where(function(member){
            			return !$linq(vm.sessionComponent.session.attendees).contains(member.id, function(x,y){
            				if(x.member && x.member.id && y == x.member.id){
            					return true;
            				}else{
            					return false;
            				}
            			});
            		}).toArray();
            		var attendeesNoInOrganMember = $linq(vm.sessionComponent.session.attendees).where(function(attendee){
            			if(attendee.member && attendee.member.id){
	            			return !$linq(vm.sessionComponent.session.organ.members).contains(attendee.member.id, function(x,y){
	            				if(x && x.id && y == x.id){
	            					return true;
	            				}else{
	            					return false;
	            				}
	            			});
            			}else{
            				return false;
            			}
            		}).toArray();
            		            		
            		if((organMemberNoInSession && organMemberNoInSession.length > 0) || (attendeesNoInOrganMember && attendeesNoInOrganMember.length > 0) || haveModifiedAttendees()){
            			DialogsFactory.confirm('global.sec.literals.confirmOrganMemberNoInSession', 'global.sec.literals.confirmOrganMemberNoInSessionBody').then(function (dataAux) {
                   	     $http({
                               url: './api/sec/session/' + vm.sessionComponent.session.id + '/convene/updateMembers',
                               method: 'POST'
                           }).then(function(data) {
                        	   $state.reload();
                           }).catch(function (error) {
	                            if(error && error.data && error.data.message && error.data.message.toUpperCase().startsWith("We are working with this session.".toUpperCase())){
									DialogsFactory.error($filter('translate')('global.literals.errorSessionWorking'), $filter('translate')('DIALOGS_ERROR_MSG'));
	                            }else{
	                            	DialogsFactory.error($filter('translate')('global.sec.literals.cantUpdateMembers'));
	                            }
                           });
                       }).catch(function (data) {
                       	//Empty;
       	            	});
                	}else{
	                	var attendeesToNotify = $linq(vm.sessionComponent.session.attendees).where(
	                        	function(x) {
	                        		var doNotification = false;
	                        		if(!x.conveneNotification && x.memberRol.id != $rootScope.app.configuration.sec_organ_member_rols.extern && x.memberRol.id != $rootScope.app.configuration.sec_organ_member_rols.notAssign) {
	                        			if(x.member && x.member.id){
	                        				var organMember = $linq(vm.sessionComponent.session.organ.members).firstOrDefault(undefined, "x => x.id == "+x.member.id);
	                        				if(organMember && organMember.attendeeAddressConvene){
	                        					doNotification = true;
	                        				}
	                        			}
	                                }
	                        		return doNotification;
	                        	}
	                        ).toArray();
	                        var attendeesNotNotifY = $linq(vm.sessionComponent.session.attendees).where(
	                        	function(x) {
	                        		var doNotification = false;
	                        		if(x.memberRol.id == $rootScope.app.configuration.sec_organ_member_rols.extern || x.memberRol.id == $rootScope.app.configuration.sec_organ_member_rols.notAssign) {
	                        			doNotification = true;
	                                }
	                        		return doNotification;
	                        	}
	                        ).toArray();
	                            
	        	           	var createNotifications = function(){
	        	           		var thirds = $linq(vm.sessionComponent.session.organ.members).intersect(vm.sessionComponent.session.attendees, function(x,y){
	                        		var haveAddress = false;
	                        		if(y.id && x.member && x.member.id && y.id == x.member.id && y.attendeeAddressConvene && y.attendeeAddressConvene.id && (!x.conveneNotification || !x.conveneNotification.id)){
	                        			haveAddress = true;
	                        		}
	                        		return haveAddress;
	                        	}).select("x => x.organMemberGlobal.attendee").distinct("(x,y) => x.id == y.id").toArray();
	        	           		
	        	           		var addresses = $linq(vm.sessionComponent.session.organ.members).intersect(vm.sessionComponent.session.attendees, function(x,y){
	                        		var haveAddress = false;
	                        		if(y.id && x.member && x.member.id && y.id == x.member.id && y.attendeeAddressConvene && y.attendeeAddressConvene.id && (!x.conveneNotification || !x.conveneNotification.id)){
	                        			haveAddress = true;
	                        		}
	                        		return haveAddress;
	                        	}).select("x => x.attendeeAddressConvene").toArray();
	        	           		var documents = $linq(vm.sessionComponent.session.documents).distinct("x => x.document.id").select("x => x.document").toArray();
	        	           		
	        	           		var archiveClassifications = undefined;
	        	                if(vm.isEdit) {
	        	                	archiveClassifications = GlobalDataFactory.archiveClassifications;
	        	                } else {
	        	                	archiveClassifications = CommonService.getTreeData(GlobalDataFactory.archiveClassifications, Language.getActiveColumn());
	        	                }
	        	           		
	        	           		var newModal =  NotificationFactory.notificationNewModal(angular.copy(thirds), documents,undefined,undefined,addresses, ((vm.sessionComponent.conveneDocument)?[vm.sessionComponent.conveneDocument.document.id]:undefined),((thirds && thirds.length > 0)?$linq(thirds).select("x=>x.id").toArray():undefined), archiveClassifications);
	        		            var submitNewNotification = function () {
	        	                    NotificationFactory.getNewNotifications(this, newModal);
	        	                }
	        	                newModal.submitModal = submitNewNotification;
	        	                AnnexaFormlyFactory.showAnnexaFormModal('modalNewNotification', newModal);
	        	        	}
	                   	 
	                        if(attendeesToNotify.length > 0) {
	                            var confirmBody = '';
	                             if(attendeesNotNotifY.length == 0) {
	                                 confirmBody = $filter('translate')('global.sec.literals.confirmNotifyAttendees');
	                             } else {
	                                 confirmBody = $filter('translate')('global.sec.literals.confirmNotifyAttendeesMissing1');
	                                 confirmBody += '<br/><br/><ul>';
	                                 _.forEach(attendeesNotNotifY, function (item) {
	                                     confirmBody += '   <li>' + item.attendee + '</li>';
	                                 });
	                                 confirmBody += '</ul><br/>';
	                                 confirmBody += $filter('translate')('global.sec.literals.confirmNotifyAttendeesMissing2');
	                             }
	                             if(vm.sessionComponent.session && vm.sessionComponent.session.modified){
	                            	 DialogsFactory.confirm('global.sec.literals.nofifyAttendees', 'global.sec.literals.nofifyAttendeesModifiedBody').then(function (btn) {
		 	 	                         DialogsFactory.confirm('global.sec.literals.nofifyAttendees', confirmBody).then(function (btn) {
		 	                            	createNotifications();
		 	                             }).catch(function (data) {
		 	         	                	//Empty
		 	         		            });
	                            	 }).catch(function (data) {
		 	         	              	//Empty
		 	         		         });
		                         }else{
		                        	 DialogsFactory.confirm('global.sec.literals.nofifyAttendees', confirmBody).then(function (btn) {
			                            	createNotifications();
			                             }).catch(function (data) {
			         	                	//Empty
			         		            });		                        	 
		                         }
	                        } else {
	                        	 DialogsFactory.error($filter('translate')('global.sec.errors.conveneErrorAll'));
	                        }
                	}
            	}else{
            		var document_types = $rootScope.app.configuration.sec_document_types["SCO"].valid_states;
            		var validStates = '';
            		var counter = 0;
            		if(document_types && document_types.length > 0){
            			_.forEach(document_types, function(types) {
            			       validStates += $filter('translate')('global.documentState.' + types);
            			       counter++;
            			       if(counter < document_types.length) {
            			    	   validStates += ',';
            			       }
            			});
            		}
            		DialogsFactory.error($filter('translate')('global.sec.errors.noConveneDocumentSigned',{valid:validStates}));
            	}
            }

            vm.updateConvene = function(){
            	DialogsFactory.confirm(vm.updateButtonLiteral, 'global.sec.literals.confirmUpdateConveneBody').then(function (dataAux) {
            	     $http({
                        url: './api/sec/session/' + vm.sessionComponent.session.id + '/convene/update',
                        method: 'POST', 
                        data: RestService.getTranslationsForSend()
                    }).then(function(data) {
                    	var acAux = vm.sessionComponent.session.archiveClassificationAux;
                        vm.sessionComponent.session = JSOG.decode(data.data);
                        vm.sessionComponent.session.archiveClassificationAux = acAux;
                    	$rootScope.$broadcast('sessionUpdatedConvene', {})
                    }).catch(function (error) {
                    	if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
							var msg = $filter('translate')('global.tram.errors.processTemplate')+((error.data.message)?'<br/><br/>'+error.data.message:'');
							DialogsFactory.error(msg, $filter('translate')('DIALOGS_ERROR_MSG'));
                    	}else if(error && error.data && error.data.message && error.data.message.toUpperCase().startsWith("We are working with this session.".toUpperCase())){
							DialogsFactory.error($filter('translate')('global.literals.errorSessionWorking'), $filter('translate')('DIALOGS_ERROR_MSG'));
						}else{
		                	DialogsFactory.error($filter('translate')('global.sec.errors.cantGenerateConvene'));
						}
                    });
                }).catch(function (data) {
                	//Empty;
	            });
            }
            
            $scope.$on('created_multiple_notifications', function (event, args) {
            	if(args && args.notifications && args.parentIsModal == "isNotModal"){
            		var notifsToDuplicate = [];
            		_.forEach(args.notifications, function(notif){
            			var notifAux = angular.copy(notif);
            			_.forEach(notif.thirds, function(third){
            				var members = $linq(vm.sessionComponent.session.organ.members).where("x => x.organMemberGlobal.attendee.id == "+third.third.id).toArray();
                			if(members && members.length > 0){
                				_.forEach(members, function(member, index){
                					var attendee = $linq(vm.sessionComponent.session.attendees).firstOrDefault(undefined, "x => x.member && x.member.id == "+member.id);
                					if(index == 0){
                						third.sessionAttendee = {id:attendee.id};
                					}else{
                						var notifAux2 = angular.copy(notifAux);
                						if(notifAux2.thirds && notifAux2.thirds.length > 0){
                							notifAux2.thirds[0].sessionAttendee = {id:attendee.id};
                							notifsToDuplicate.push(notifAux2);
                						}
                					}
                				});
                			}
            			});
            		});
            		if(notifsToDuplicate && notifsToDuplicate.length > 0){
            			_.forEach(notifsToDuplicate, function(notif){
            				args.notifications.push(notif);
            			});
            		}
                    NotificationFactory.createMultipleNotifications(args.notifications).then(function(data) {
                    	if(data && data.length > 0){
                    		$state.reload();
                    	}
                    }).catch(function (error) {
                    	if(error && error.data && error.data.message == 'No notification type'){
                    		DialogsFactory.error($filter('translate')('global.sec.errors.noNotificationType'));
                    	}else if(error && error.data && error.data.message == 'No Template Agreement'){
                    		DialogsFactory.error($filter('translate')('global.sec.errors.noTemplateAgreement'));
                    	}else if(error && error.data && error.data.message == 'No session or no proposed order'){
                    		DialogsFactory.error($filter('translate')('global.sec.errors.noSessionOrNoProposedOrder'));
                    	}else if(error && error.data && error.data.message == 'Error creating session ava'){
                    		DialogsFactory.error($filter('translate')('global.sec.errors.errorCreatingSessionAva'));
                    	}else if(error && error.data && error.data.message == 'Error connecting ava'){
                    		DialogsFactory.error($filter('translate')('global.sec.errors.errorConnectingAva'));
                    	}else if(error && error.data && error.data.message == 'Error invalid token ava'){
                    		DialogsFactory.error($filter('translate')('global.sec.errors.invalidTokenAva'));
                    	}else if(error && error.data && error.data.message == 'Error organ dont exist ava'){
                    		DialogsFactory.error($filter('translate')('global.sec.errors.organDontExistAva'));
                    	}else if(error && error.data && error.data.message == 'Bad method ava'){
                    		DialogsFactory.error($filter('translate')('global.sec.errors.badMethodAva'));
                    	}else if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
							var msg = $filter('translate')('global.tram.errors.processTemplate')+((error.data.message)?'<br/><br/>'+error.data.message:'');
							DialogsFactory.error(msg, $filter('translate')('DIALOGS_ERROR_MSG'));
						}else if(error && error.data && error.data.message === "Is not valid document from template not found"){
							DialogsFactory.error($filter('translate')('global.documents.definitiveNotificationNoValidError'));
						}else{
                    		DialogsFactory.error($filter('translate')('global.sec.errors.cantGenerateConvene'));
                    	}
                    	console.error(error);
                    });
            	}
            });

            
            $scope.$on('sessionPointOrderAdded', function(event, args) {
                if(args.origin && args.origin == 'proposedOrder') {
                	vm.proposedOrder = CommonService.getNestableData(angular.copy(vm.sessionComponent.session.proposedOrder), 'proposedOrder', 'orderView');
                	
                	angular.forEach(vm.proposedOrder, function(value) {
                		vm.addProposedOrderCollapsed(value);
                	});
                	
                }
            });
            
            
            $scope.$on('sessionPointOrderDeleted', function(event, args) {
                if(args.origin && args.origin == 'proposedOrder') {
                	vm.proposedOrder = CommonService.getNestableData(angular.copy(vm.sessionComponent.session.proposedOrder), 'proposedOrder', 'orderView');
                }
            });
            

            $scope.$on('sessionPointOrderUpdated', function(event, args) {
                if(args.origin && args.origin == 'proposedOrder') {
                	vm.proposedOrder = CommonService.getNestableData(angular.copy(vm.sessionComponent.session.proposedOrder), 'proposedOrder', 'orderView');
                }
            });
            
            vm.initProposedOrderCollapsed = function(value) {
            	if(value.proposedOrder && value.proposedOrder.id) {
            		vm.proposedOrderCollapsed.push({id: value.proposedOrder.id, collapsed: true});
            		if(value.nodes && value.nodes.length > 0){
            			angular.forEach(value.nodes, function(node) {
                    		vm.initProposedOrderCollapsed(node);
                        });
            		}
            	}
            }
            
            vm.addProposedOrderCollapsed = function(value) {
            	if(!$linq(vm.proposedOrderCollapsed).contains(value.proposedOrder, "(x, y) => x.id == y")) {
            		vm.proposedOrderCollapsed.push({id: value.proposedOrder.id, collapsed: true});
            	}
            	if(value.nodes && value.nodes.length > 0){
        			angular.forEach(value.nodes, function(node) {
                		vm.addProposedOrderCollapsed(node);
                    });
        		}
            }
            
            vm.isCollapsed = function(id) {
            	return $linq(vm.proposedOrderCollapsed).firstOrDefault(undefined,"x => x.id == "+id).collapsed;
            }
            
            vm.toggleNode = function(id, collapsed){
            	$linq(vm.proposedOrderCollapsed).firstOrDefault(undefined,"x => x.id == "+id).collapsed = collapsed;
            }
            
            vm.toggleNodeAll = function(){
            	vm.collapsedAll = !vm.collapsedAll;
            	angular.forEach(vm.proposedOrderCollapsed, function(item) {
                    item.collapsed = vm.collapsedAll;
                });
            }
            
            this.$onInit = function(){

            	vm.collapsedAll = true;
            	
            	vm.proposedOrder = CommonService.getNestableData(angular.copy(vm.sessionComponent.session.proposedOrder), 'proposedOrder', 'orderView');
            	
            	vm.proposedOrderCollapsed = [];
                
            	angular.forEach(vm.proposedOrder, function(value) {
            		vm.initProposedOrderCollapsed(value);
                });
            	
            	vm.treeOptions = {
    	            beforeDrop: function(e){
    	            	var deferred = $q.defer();
    	            	var destValueOK = true;
    	            	var destValue = e.dest.nodesScope.node ? e.dest.nodesScope.node.proposedOrder: null;
    	                if(destValue != null && destValue != undefined && destValue.type && destValue.type.type != 'GROUP') {
    	                	destValueOK = false;
    	                }
    	            	if(!destValueOK){
    	            		DialogsFactory.error($filter('translate')('global.sec.literals.nodeParentIsNotAGroup'));
    	                	deferred.reject(false);
    	            	}else{
    	            		if(
    	            				(!destValue && e.source.nodeScope && e.source.nodeScope.$modelValue && e.source.nodeScope.$modelValue.proposedOrder && (!e.source.nodeScope.$modelValue.proposedOrder.parent || !e.source.nodeScope.$modelValue.proposedOrder.parent.id)) 
    	            				|| (destValue && e.source.nodeScope && e.source.nodeScope.$modelValue && e.source.nodeScope.$modelValue.proposedOrder && e.source.nodeScope.$modelValue.proposedOrder.parent && destValue.id == e.source.nodeScope.$modelValue.proposedOrder.parent.id)
    	            		){
    	            			if(e.dest.index == e.source.index){
    	            				destValueOK = false;
    	            			}
    	            		}
    	            		if(!destValueOK){
    	            			deferred.reject(false);
    	            		}else{
    	            			DialogsFactory.confirm('global.sec.literals.movePoint', 'global.sec.literals.confirmmovePoint').then(function (dataAux) {
    	    	            		var sourceValue = e.source.nodeScope.$modelValue.proposedOrder;
    	        	                var sourceParentValue = null;
    	        	                if( e.source.nodeScope.$modelValue.proposedOrder.parent != null &&  e.source.nodeScope.$modelValue.proposedOrder.parent != undefined) {
    	        	                    sourceParentValue = e.source.nodeScope.$modelValue.proposedOrder.parent.id;
    	        	                }
    	        	                var destValue = e.dest.nodesScope.node ? e.dest.nodesScope.node.proposedOrder: null;
    	        	                if(destValue != null && destValue != undefined) {
	        	                        sourceValue.parent = destValue;
    	        	                }else {
    	        	                    sourceValue.parent = null;
    	        	                }
    	        	                sourceValue.orderView = e.dest.index+1;
        	                        savePointTree(sourceValue, deferred);
    	    	                }).catch(function (data) {
    	    	                	deferred.reject(false);
    	    		            });    	            			
    	            		}
    	            	}
    	            	return deferred.promise;
    	            }
    	        };
            }
        }]
    })
