/**
 * Created by osirvent on 04/05/2017.
 */
angular
    .module('annexaApp')
    .component('annexaOrganMembers', {
        templateUrl: './components/sec/annexa-organ-members/annexa-organ-members.html',
        controller: ['CommonService', 'Language', 'RestService', '$rootScope', '$filter', 'RestService', 'CommonAdminModals', 'AnnexaFormlyFactory', 'DialogsFactory','$scope', 'GlobalDataFactory', 'CacheFactory', 'SecFactory', 
        function (CommonService, Language, RestService, $rootScope, $filter, RestService, CommonAdminModals, AnnexaFormlyFactory, DialogsFactory, $scope, GlobalDataFactory, CacheFactory, SecFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            vm.showLongAddressLanguage = $rootScope.app.configuration.show_long_address_language && $rootScope.app.configuration.show_long_address_language.value;
            vm.addOrganMember = function () {
            	var modal = angular.copy(CommonAdminModals.organMemberNew);
                modal.annexaFormly.model = {};
                modal.annexaFormly.options = { watchAllExpressions: true };
                if( modal.annexaFormly.fields && modal.annexaFormly.fields.length > 0 && modal.annexaFormly.fields[0].fieldGroup && modal.annexaFormly.fields[0].fieldGroup.length > 2 && modal.annexaFormly.fields[0].fieldGroup[2]){
                	modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.existSecretary = ($linq(vm.members).count("x => x.memberRol.id == "+$rootScope.app.configuration.sec_organ_member_rols.secretary) > 0);
                	modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.existPresident = ($linq(vm.members).count("x => x.memberRol.id == "+$rootScope.app.configuration.sec_organ_member_rols.president) > 0);
                	modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.added = $linq(vm.members).select("x => x.organMemberGlobal.id").toArray();
                	modal.annexaFormly.fields[0].fieldGroup[5].hideExpression = function ($viewValue, $modelValue, scope) {
                		return ((vm.organ && vm.organ.weightedVote && scope.model && scope.model.vote)?false:true);
                	};
                }
                AnnexaFormlyFactory.showModal('modalNewOrganMember', modal, vm.saveOrganMember, false);
            }
            
            vm.saveOrganMember = function(organMember){
            	if(organMember && organMember.annexaFormly && organMember.annexaFormly.model && organMember.annexaFormly.model.row_member){
		        	var member = {
		        		organMemberGlobal:((organMember.annexaFormly.model.row_member.organMemberGlobal)?organMember.annexaFormly.model.row_member.organMemberGlobal:undefined),
		        		attendeeAddressConvene:((organMember.annexaFormly.model.row_member.attendeeAddressConvene)?organMember.annexaFormly.model.row_member.attendeeAddressConvene:undefined),
		        		memberRol:((organMember.annexaFormly.model.row_member.memberRol)?organMember.annexaFormly.model.row_member.memberRol:undefined),
		        		vote:((organMember.annexaFormly.model.row_member.vote)?organMember.annexaFormly.model.row_member.vote:false),
		        		orderView:((organMember.annexaFormly.model.row_member.orderView)?organMember.annexaFormly.model.row_member.orderView:((vm.members && vm.members.length)?$linq(vm.members).select("x=>x.orderView").max()+1:1)),
		        		weightedVote:((organMember.annexaFormly.model.row_member.weightedVote && organMember.annexaFormly.model.row_member.vote && vm.organ && vm.organ.weightedVote)?organMember.annexaFormly.model.row_member.weightedVote:undefined)
		        	};
		        	if(!vm.isNew){
		        		if(member && vm.organ && vm.organ.id){
		        			var memberAux = angular.copy(member);
		        			memberAux.organMemberGlobal=((member.organMemberGlobal)?{id:member.organMemberGlobal.id}:undefined);
		        			memberAux.attendeeAddressConvene=((member.attendeeAddressConvene)?{id:member.attendeeAddressConvene.id}:undefined);
		        			memberAux.memberRol=((member.memberRol)?{id:member.memberRol.id}:undefined);
		        			memberAux.vote=((member.vote)?member.vote:false);
		        			memberAux.orderView=((member.orderView)?member.orderView:-1);
		        			memberAux.weightedVote= ((member.weightedVote && member.vote && vm.organ && vm.organ.weightedVote)?member.weightedVote:undefined);
		        			memberAux.organ= {id: vm.organ.id};
                    		RestService.insert('./api/sec/organ_member', memberAux).then(function(data) {
                    			member.id = data.id;
                    			member.organ = data.organ;
                    			member.orderView = data.orderView;
                    			member.createdDate = data.createdDate;
                    			if(!vm.members){
                                	vm.members = [];
                                }
                                vm.members.push(member);
                                _.forEach(vm.members, function(mem){
                            		if(data.id != mem.id && mem.orderView >= data.orderView){
                            			mem.orderView++;
                            		}
                            	});
                            	organMember.close();
                            }).catch(function() {
                            	organMember.alerts.push("Error");
                            });
                        }
		        	}else{
		        		if(vm.members){
		        			_.forEach(vm.members, function(mem){
                    			if(mem.orderView >= member.orderView){
                    				mem.orderView++;
                    			}
                    		});
		        		}
		        		vm.members.push(member);
		        		organMember.close();
		        	}
            	}
            }

            vm.editOrganMember = function (member) {
            	var modal = angular.copy(CommonAdminModals.organMemberNew);
            	var memberToUpdate = angular.copy(member);
            	if(memberToUpdate.organMemberGlobal && memberToUpdate.organMemberGlobal.attendee && memberToUpdate.organMemberGlobal.attendee.completeName){
            		memberToUpdate.organMemberGlobal.completeName = memberToUpdate.organMemberGlobal.attendee.completeName
            	}
            	modal.annexaFormly.model = {row_member: memberToUpdate};
            	modal.data = memberToUpdate.orderView;
                modal.annexaFormly.options = { watchAllExpressions: true };
                if( modal.annexaFormly.fields && modal.annexaFormly.fields.length > 0 && modal.annexaFormly.fields[0].fieldGroup && modal.annexaFormly.fields[0].fieldGroup.length > 2 && modal.annexaFormly.fields[0].fieldGroup[2]){
                    modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.existSecretary = ($linq(vm.members).count("x => x.organMemberGlobal.id != "+memberToUpdate.organMemberGlobal.id+" && x.memberRol.id == "+$rootScope.app.configuration.sec_organ_member_rols.secretary) > 0);
                    modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.existPresident = ($linq(vm.members).count("x => x.organMemberGlobal.id != "+memberToUpdate.organMemberGlobal.id+" && x.memberRol.id == "+$rootScope.app.configuration.sec_organ_member_rols.president) > 0);
                	modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.added = $linq(vm.members).select("x => x.organMemberGlobal.id").toArray();
                	modal.annexaFormly.fields[0].fieldGroup[5].hideExpression = function ($viewValue, $modelValue, scope) {
                		return ((vm.organ && vm.organ.weightedVote && scope.model && scope.model.vote)?false:true);
                	};
                }
            	AnnexaFormlyFactory.showModal('modalNewOrganMember', modal, vm.updateOrganMember, false);
            }
            
            vm.updateOrganMember = function(organMember){
            	if(organMember && organMember.annexaFormly && organMember.annexaFormly.model && organMember.annexaFormly.model.row_member){
		        	var member = angular.copy(organMember.annexaFormly.model.row_member);
		        	if(member){
		        		var memberIndex = -1;
		        		if(member.id){
				        	memberIndex = $linq(vm.members).indexOf("x => x.id == "+member.id);
		        		}else{
				        	memberIndex = $linq(vm.members).indexOf("x => x.organMemberGlobal && x.organMemberGlobal.id == "+member.organMemberGlobal.id);
		        		}
		        		if(memberIndex > -1){
				        	if(!vm.isNew){
				        		if(member && vm.organ && vm.organ.id){
				        			var memberAux = angular.copy(member);
				        			memberAux.organMemberGlobal=((member.organMemberGlobal)?{id:member.organMemberGlobal.id}:undefined);
				        			memberAux.attendeeAddressConvene=((member.attendeeAddressConvene)?{id:member.attendeeAddressConvene.id}:undefined);
				        			memberAux.memberRol=((member.memberRol)?{id:member.memberRol.id}:undefined);
				        			memberAux.vote=((member.vote)?member.vote:false);
				        			memberAux.orderView=((member.orderView)?member.orderView:-1);
				        			memberAux.organ= {id: vm.organ.id};
		        	            	memberAux.weightedVote = ((memberAux.vote && vm.organ && vm.organ.weightedVote)?memberAux.weightedVote:undefined);
		                    		RestService.update('./api/sec/organ_member/' + memberAux.id, memberAux).then(function (data) {
		                    			if(memberIndex || memberIndex == 0){
			                    			if(vm.members && vm.members.length >= memberIndex){
			                                	vm.members[memberIndex] = member;
			                                	if(data && organMember.data != data.orderView){
			                                		_.forEach(vm.members, function(mem){
			                                			if(data.id != mem.id){ 
			                                			    if(organMember.data > data.orderView){
																if(mem.orderView >= data.orderView && mem.orderView <= organMember.data){
																	mem.orderView++;
																}
			                                			    }else{
			                                			    	if(mem.orderView >= organMember.data && mem.orderView <= data.orderView){
																	mem.orderView--;
																}
			                                			    }
			                                			}
			                                		});
			                                	}
			                                }
		                    			}
		                    			organMember.close();
		                            }).catch(function () {
		                            	organMember.alerts.push("Error");
		                            });
		                        }
				        	}else{
				        		if(vm.members){
				        			_.forEach(vm.members, function(mem){
                        			    if(organMember.data > member.orderView){
											if(mem.orderView >= member.orderView && mem.orderView <= organMember.data){
												mem.orderView++;
											}
                        			    }else{
                        			    	if(mem.orderView >= organMember.data && mem.orderView <= member.orderView){
												mem.orderView--;
											}
                        			    }
                            		});
				        		}
				        		vm.members[memberIndex] = member;
				        		organMember.close();
				        	}
			        	}
		        	}
            	}
            }
            
            vm.deleteOrganMember = function (member) {
               	DialogsFactory.confirm('global.sec.literals.confirmRemoveOrganMemberTitle', 'global.sec.literals.confirmRemoveOrganMemberBody').then(function (dataAux) {
           			if(member){
		        		var memberIndex = -1;
		        		if(member.id){
				        	memberIndex = $linq(vm.members).indexOf("x => x.id == "+member.id);
		        		}else{
				        	memberIndex = $linq(vm.members).indexOf("x => x.organMemberGlobal && x.organMemberGlobal.id == "+member.organMemberGlobal.id);
		        		}
		        		var orderView = member.orderView;
		        		if(memberIndex > -1){
				        	if(!vm.isNew){
				        		if(member && member.id && ( memberIndex || memberIndex == 0) && vm.members && vm.members.length >= memberIndex){
		                    		RestService.delete('./api/sec/organ_member/' + member.id).then(function (data) {
		                    			vm.members.splice(memberIndex, 1);
	                    				_.forEach(vm.members, function(mem){
                                			if(mem.orderView >= orderView){
                                				mem.orderView--;
                                			}
                                		});
	                                }).catch(function (error) {
		                                console.log(error);
		                            });
		        	                
		                        }
				        	}else{
				        		vm.members.splice(memberIndex, 1);
				        		_.forEach(vm.members, function(mem){
                        			if(mem.orderView >= orderView){
                        				mem.orderView--;
                        			}
                        		});
				        	}
			        	}
		        	}
                }).catch(function (data) {
	                    //Empty
	            });
            }
            
            $scope.$on('selectOrganWeightedVote', function(event, args) {
            	var val = undefined;
            	if(args && args.item && args.item.id){
            		val = 1;
            	}
            	if(vm.members && vm.members.length > 0){
            		_.forEach(vm.members, function(mem){
            			if(mem.vote){
            				if(!(val && mem.weightedVote)){
            					mem.weightedVote = val;
            				}
            			}else{
            				mem.weightedVote = undefined;
            			}
            		})
            	}
            });
            
            vm.doSpokesPerson = function (member) {
            	if(vm.organ && vm.organ.weightedVote && member && member.vote){
            		DialogsFactory.confirm('global.sec.literals.doSpokesPerson', 'global.sec.literals.confirmDoSpokesPersonBody').then(function (dataAux) {
	           			if(member && member.organMemberGlobal && member.organMemberGlobal.politicalParty && member.organMemberGlobal.politicalParty.id){
	           				if(!vm.isNew){
	           					SecFactory.setSpokesPerson(member.id).then(function (data) {
	           						if(data){
	           							if(data[member.id] !== undefined){
	           								member.weightedVote = data[member.id];
	           							}
	           							_.forEach(vm.members, function(m){
	           								if(data[m.id] !== undefined){
		           								m.weightedVote = data[m.id];
		           							}
	           							});
	           						}
	           					}).catch(function (error) {
	           						console.log(error);
	           					});
	           				}else{
	           					SecFactory.getPoliticalPartyNumberOfVotes(member.organMemberGlobal.politicalParty.id).then(function (data) {
	           						if(data){
	           							member.weightedVote = data;
	           							if(vm.organ.presidentVoteSeparately && member.weightedVote && member.weightedVote > 0 && !($rootScope.app && $rootScope.app.configuration 
	           									&& $rootScope.app.configuration.sec_organ_member_rols && $rootScope.app.configuration.sec_organ_member_rols.president 
												&& member.memberRol && member.memberRol.id && member.memberRol.id === $rootScope.app.configuration.sec_organ_member_rols.president)) {
	           								member.weightedVote = member.weightedVote-1;
	           							}
	           							_.forEach(vm.members, function(m){
	           								if(m && m.organMemberGlobal && m.organMemberGlobal.politicalParty && m.organMemberGlobal.politicalParty.id && m.organMemberGlobal.politicalParty.id === member.organMemberGlobal.politicalParty.id){
	           									if(m.organMemberGlobal.id !== member.organMemberGlobal.id){
	           										if(vm.organ.presidentVoteSeparately){
	           											if(!($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.sec_organ_member_rols && $rootScope.app.configuration.sec_organ_member_rols.president 
	           													&& m.memberRol && m.memberRol.id && m.memberRol.id === $rootScope.app.configuration.sec_organ_member_rols.president)){
	           												m.weightedVote = 0;
	           											}
	           										}else{
	           											m.weightedVote = 0;
	           										}
	           									}
	           								}
	           							});
	           						}
	           					}).catch(function (error) {
	           						console.log(error);
	           					});
	           				}
		        	                
		                }else if(member){
		                	member.weightedVote = 1;
		                }
	                }).catch(function (data) {
		                    //Empty
		            });
            	}
            }
            
            this.$onInit = function(){
            	if(vm.members && vm.members.length > 0){
	                _.forEach(vm.members, function (value, index) {
	                	if(value.attendeeAddressConvene && value.attendeeAddressConvene.id){
	                		var thirdAllAddress = new ThirdAddress(undefined, vm.languageColumn, undefined, vm.showLongAddressLanguage);
	                		value.attendeeAddressConvene.name = thirdAllAddress.getAddressHtml(value.attendeeAddressConvene, true, true);
	                	}
	                });
            	}
            }
            
        }],
        bindings: {
            members: '=',
            isNew: '=',
            organ:'=?'
        }
    })